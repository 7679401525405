<script>
/**
 * Progress Corr-Rég component
 */
export default {
  props: ["countByMonths"],
  created() {},
  data() {
    return {
      source1: {
        series: [],
        chartOptions: {
          chart: {
            animations: {
              enabled: false,
            },
            zoom: {
              enabled: true,
            },
            toolbar: {
              show: false,
            },
          },
          stroke: {
            curve: "straight",
            width: 2,
          },
          colors: ["#F42020"],
          xaxis: {
            axisTicks: {
              show: true,
              borderType: "solid",
              color: "#78909C",
              height: 6,
              offsetX: 0,
              offsetY: 0,
            },
          },
          grid: {
            borderColor: "#78ACD8",
          },
          tooltip: {
            x: {
              show: true,
            },
            y: {
              formatter: (value) => {
                return value;
              },
              title: {
                formatter: (seriesName) => seriesName,
              },
            },
          },
          markers: {
            size: 2,
            hover: {
              size: 5,
              sizeOffset: 3,
            },
          },
          noData: {
            text: this.$t("charts.noData.text"),
            align: "center",
            verticalAlign: "middle",
            style: {
              fontSize: "20px",
            },
          },
        },
      },
      source2: {
        series: [],
        chartOptions: {
          chart: {
            animations: {
              enabled: false,
            },
            zoom: {
              enabled: true,
            },
            toolbar: {
              show: false,
            },
          },
          stroke: {
            curve: "straight",
            width: 2,
          },
          colors: ["#F42020"],
          xaxis: {
            axisTicks: {
              show: true,
              borderType: "solid",
              color: "#78909C",
              height: 6,
              offsetX: 0,
              offsetY: 0,
            },
          },
          grid: {
            borderColor: "#78ACD8",
          },
          tooltip: {
            x: {
              show: true,
            },
            y: {
              formatter: (value) => {
                return value;
              },
              title: {
                formatter: (seriesName) => seriesName,
              },
            },
          },
          markers: {
            size: 2,
            hover: {
              size: 5,
              sizeOffset: 3,
            },
          },
          noData: {
            text: this.$t("charts.noData.text"),
            align: "center",
            verticalAlign: "middle",
            style: {
              fontSize: "20px",
            },
          },
        },
      },
    };
  },
  watch: {
    countByMonths(newVal) {
      let dataCorr = [];
      let dataRegl = [];
      newVal.forEach((obj) => {
        const date = new Date(obj.year, obj.month - 1, 1);
        const month = date.toLocaleString('default', { month: 'long' });
        dataCorr.push({
          x: month+' / '+obj.year,
          y: obj.totalNbCorr
        });
        dataRegl.push({
          x: month+' / '+obj.year,
          y: obj.totalNbRegl
        })
      });
      this.source1.series = [];
      this.source2.series = [];
      this.source1.series.push({
        name: "Progress Corr",
        data: dataCorr
      });
      this.source2.series.push({
        name: "Progress Regl",
        data: dataRegl
      });
    },
  },
  mounted() {},
  methods: {},
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <h4 class="card-title mb-4">Progress de Corrective</h4>
        </div>
      </div>
      <div>
        <div id="line-column-chart" class="apex-charts" dir="ltr"></div>
        <apexchart
          class="apex-charts"
          height="250"
          type="line"
          dir="ltr"
          :series="source1.series"
          :options="source1.chartOptions"
        ></apexchart>
      </div>
      <div class="row">
        <div class="col-12">
          <h4 class="card-title mb-4">Progress de Réglage</h4>
        </div>
      </div>
      <div>
        <div id="line-column-chart" class="apex-charts" dir="ltr"></div>
        <apexchart
          class="apex-charts"
          height="250"
          type="line"
          dir="ltr"
          :series="source2.series"
          :options="source2.chartOptions"
        ></apexchart>
      </div>
    </div>
  </div>
</template>