<script>
/**
 * Widget Component
 */
export default {
  props: ["data"],
  created() {
  },
  data() {
    return {
      statData: [
        {
          id: 1,
          title: "Maintenance Préventive",
          value: "-",
        },
        {
          id: 2,
          title: "Maintenance Corrective",
          value: "-",
        },
        {
          id: 3,
          title: "Maintenance de Réglage",
          value: "-",
        },
      ],
    };
  },
  watch: {
    data(newVal) {
      let data = newVal[0];
      this.statData = [
        {
          id: 1,
          title: "Maintenance Préventive",
          value: data.totalNbPrev + "/4100",
        },
        {
          id: 2,
          title: "Maintenance Corrective",
          value: data.totalNbCorr,
        },
        {
          id: 3,
          title: "Maintenance de Réglage",
          value: data.totalNbRegl,
        },
      ];
    },
  },
};
</script>

<template>
  <div class="row pb-0">
    <div class="col-12 col-md-4 pb-0" v-for="(data, index) in statData" :key="index">
      <div class="card h-75 pb-4">
        <div class="card-body">
          <div class="row">
            <div class="col-12 pb-0">
              <h3 class="mb-0 mr-1" style="display: inline">
                {{ data.value }}
              </h3>
            </div>
          </div>
          <div class="row">
            <div class="col-12 pt-2 pb-0">
              <h3 class="card-title">{{ data.title }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>