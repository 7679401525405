<script>
/**
 * Sources Component
 */
export default {
  props: ["countByRegion"],
  created() {
  },
  data() {
    return {
      chartData: [

      ],
      chartOptions: {
        chart: {
          type: "bar",
          stacked: true,
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "20%",
            colors: {
              backgroundBarColors: ["#E8E8E8"],
            },
          },
        },
        colors: ["#F42020"],
        stroke: {
          width: 0,
        },
        tooltip: {
            enabled: false
        }
      },
    };
  },
  watch: {
    countByRegion(newVal) {
      let chartAllData = [];
      newVal.forEach((obj) => {
        if(obj.totalNbPrev > 0)
        chartAllData.push({
          series: [
            {
              data: [obj.totalNbPrev],
            },
          ],
          options: {
            ...this.chartOptions,
            title: {
              floating: true,
              offsetX: -10,
              offsetY: 3,
              text: obj.region.name,
            },
            subtitle: {
              floating: true,
              align: "right",
              offsetY: 0,
              text: obj.totalNbPrev,
              style: {
                fontWeight: "bold",
                fontSize: "14px",
              },
            },
            yaxis: {
              max: 100,
            },
          },
        });
      });
      this.chartData = chartAllData;
    },
  }
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title mb-4">Recap de maintenance préventive</h4>
      <div class="row mt-1" v-if="chartData.length < 1">
        <div class="col-12 text-center">
          {{$t("charts.noData.text")}}
        </div>
      </div>
      <div class="row">
        <div class="col-6 pb-0 pt-0" v-for="(item, index) in chartData" :key="index">
          <apexchart
            class="apex-charts"
            height="50"
            dir="ltr"
            :series="item.series"
            :options="item.options"
            type="bar"
          ></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>