<template>
  <div>
    <b-modal
      id="add-mtcevent-modal"
      ref="add-mtcevent-modal"
      size="md"
      @hidden="cancel"
      :title="$t('modals.addMtcEvent.text')"
    >
      <div class="row">
        <!-- Region -->
        <div class="col-6 col-lg-6">
          <label for="region">{{ $t("modals.region.text") }}</label>
          <b-form-select
            id="region"
            v-model="region"
            :options="regionsData"
            class="mb-1"
            :state="err.region ? false : null"
          ></b-form-select>
          <b-form-invalid-feedback>
            {{ err.region }}
          </b-form-invalid-feedback>
        </div>
        <!-- Province -->
        <div class="col-6 col-lg-6">
          <label for="province">{{ $t("modals.province.text") }}</label>
          <b-form-select
            id="province"
            v-model="mtceventAdd.province"
            :options="provincesData"
            class="mb-1"
            :state="err.province ? false : null"
          ></b-form-select>
          <b-form-invalid-feedback>
            {{ err.province }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <!-- Preventive -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="nbPrev">Préventive</label>
          <b-form-input
            id="nbPrev"
            type="number"
            v-model="mtceventAdd.nbPrev"
            class="mb-1"
            placeholder="Préventive"
            :state="err.nbPrev ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.nbPrev }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <!-- Corrective -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="nbCorr">Corrective</label>
          <b-form-input
            id="nbCorr"
            type="number"
            v-model="mtceventAdd.nbCorr"
            class="mb-1"
            placeholder="Corrective"
            :state="err.nbCorr ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.nbCorr }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <!-- Réglage -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="nbRegl">Réglage</label>
          <b-form-input
            id="nbRegl"
            type="number"
            v-model="mtceventAdd.nbRegl"
            class="mb-1"
            placeholder="Réglage"
            :state="err.nbRegl ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.nbRegl }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="row p-2">
          <div class="col-10 text-right p-1">
            <b-button variant="success" class="text-white" @click="add(ok)">
              {{ $t("buttons.add.text") }}
            </b-button>
          </div>
          <div class="col-2 text-center p-1">
            <b-button @click="cancel()">
              {{ $t("buttons.cancel.text") }}
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ["regions", "provinces"],
  created() {},
  data() {
    return {
      regionsData: [
        { value: null, text: this.$t("titles.regions.text"), disabled: true },
      ],
      provincesData: [
        { value: null, text: this.$t("titles.provinces.text"), disabled: true },
      ],
      region: null,
      mtceventAdd: {
        region: null,
        province: null,
        nbPrev: 0,
        nbCorr: 0,
        nbRegl: 0,
      },
      err: {
        region: null,
        province: null,
        nbPrev: null,
        nbCorr: null,
        nbRegl: null,
      },
    };
  },
  watch: {
    region(newVal) {
      Object.assign(this.mtceventAdd, { region: newVal, province: null });
      this.retrieveProvinces(newVal);
    },
    regions(newVal) {
      let regionsList = newVal;
      regionsList.map((obj) => {
        this.regionsData.push({ value: obj.id, text: obj.name });
      });
    },
  },
  methods: {
    retrieveProvinces(region) {
      this.provincesData = [
        { value: null, text: this.$t("titles.provinces.text"), disabled: true },
      ];
      let provinces = this.provinces;
      provinces.map((obj) => {
        if (obj.region == region)
          this.provincesData.push({ value: obj.id, text: obj.name });
      });
    },
    cancel() {
      this.clear();
    },
    clear() {
      (this.region = null),
        (this.mtceventAdd = {
          region: null,
          province: null,
          nbPrev: 0,
          nbCorr: 0,
          nbRegl: 0,
        });
      this.err = {
        region: null,
        province: null,
        nbPrev: null,
        nbCorr: null,
        nbRegl: null,
      };
    },
    add(ok) {
      if (!this.mtceventAdd.region)
        this.err.region = this.$t("msgError.obligField.text");
      else this.err.region = "";

      if (!this.mtceventAdd.province)
        this.err.province = this.$t("msgError.obligField.text");
      else this.err.province = "";

      if (!this.mtceventAdd.nbPrev)
        this.err.nbPrev = this.$t("msgError.obligField.text");
      else this.err.nbPrev = "";

      if (!this.mtceventAdd.nbCorr)
        this.err.nbCorr = this.$t("msgError.obligField.text");
      else this.err.nbCorr = "";

      if (!this.mtceventAdd.nbRegl)
        this.err.nbRegl = this.$t("msgError.obligField.text");
      else this.err.nbRegl = "";

      if (
        !this.err.region ||
        !this.err.province ||
        !this.err.nbPrev ||
        !this.err.nbCorr ||
        !this.err.nbRegl
      ) {
        ok();
        this.$emit("addMtcEvent", this.mtceventAdd);
      }
    },
  },
};
</script>