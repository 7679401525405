<script>
/**
 * Progress préventive component
 */
export default {
  props: ["countByMonths"],
  created() {},
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          animations: {
            enabled: false,
          },
          zoom: {
            enabled: true,
          },
          toolbar: {
            show: false,
          },
        },
        stroke: {
          curve: "straight",
          width: 2,
        },
        colors: ["#F42020"],
        xaxis: {
          axisTicks: {
            show: true,
            borderType: "solid",
            color: "#78909C",
            height: 6,
            offsetX: 0,
            offsetY: 0,
          },
          //tickPlacement: 'between',
          //range: 1000 * 3600,
        },
        grid: {
          borderColor: "#78ACD8",
        },
        tooltip: {
          x: {
            show: true,
          },
          y: {
            formatter: (value) => {
              return value;
            },
            title: {
              formatter: (seriesName) => seriesName,
            },
          },
        },
        markers: {
          size: 2,
          hover: {
            size: 5,
            sizeOffset: 3,
          },
        },
        noData: {
          text: this.$t("charts.noData.text"),
          align: "center",
          verticalAlign: "middle",
          style: {
            fontSize: "20px",
          },
        },
      },
    };
  },
  watch: {
    countByMonths(newVal) {
      let data = [];
      newVal.forEach((obj) => {
        const date = new Date(obj.year, obj.month - 1, 1);
        const month = date.toLocaleString('default', { month: 'long' });
        data.push({
          x: month+' / '+obj.year,
          y: obj.totalNbPrev
        })
      });
      this.series = [];
      this.series.push({
        name: "Progress Prev",
        data: data
      })
    },
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <h4 class="card-title mb-4">Progress du préventive</h4>
        </div>
      </div>
      <div>
        <div id="line-column-chart" class="apex-charts" dir="ltr"></div>
        <apexchart
          class="apex-charts"
          height="250"
          type="line"
          dir="ltr"
          :series="series"
          :options="chartOptions"
        ></apexchart>
      </div>
    </div>
  </div>
</template>