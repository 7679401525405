<script>
/**
 * Logs Data component
 */
import moment from "moment";
import EditModal from "./update-event";
import { authComputed, mtcEventsMethods } from "@/state/helpers";
export default {
  props: ["events", "regions", "provinces", "busy", "loaderStatus"],
  components: {
    EditModal,
  },
  data() {
    return {
      event: {},
      eventsList: [],
    };
  },
  computed: {
    ...authComputed,
    fieldsComputed() {
      if (this.loggedIn.user.role != "USER") {
        return [
          {
            key: "date",
            sortable: false,
            label: this.$t("dataTable.dateTime.text"),
          },
          {
            key: "region",
            sortable: false,
            label: this.$t("dataTable.region.text"),
          },
          {
            key: "province",
            sortable: false,
            label: this.$t("dataTable.province.text"),
          },
          {
            key: "nbPrev",
            sortable: false,
            label: "Préventive",
          },
          {
            key: "nbCorr",
            sortable: false,
            label: "Corrective",
          },
          {
            key: "nbRegl",
            sortable: false,
            label: "Réglage",
          },
          {
            key: "actions",
            sortable: false,
            label: this.$t("dataTable.tools.text"),
            class: "tdClass",
          },
        ];
      } else {
        return [
          {
            key: "date",
            sortable: false,
            label: this.$t("dataTable.dateTime.text"),
          },
          {
            key: "region",
            sortable: false,
            label: this.$t("dataTable.region.text"),
          },
          {
            key: "province",
            sortable: false,
            label: this.$t("dataTable.province.text"),
          },
          {
            key: "nbPrev",
            sortable: false,
            label: "Préventive",
          },
          {
            key: "nbCorr",
            sortable: false,
            label: "Corrective",
          },
          {
            key: "nbRegl",
            sortable: false,
            label: "Réglage",
          },
        ];
      }
    },
  },
  mounted() {},
  methods: {
    ...mtcEventsMethods,
    showModalEdit() {
      this.$bvModal.show("edit-event-modal");
    },
    hideModalEdit() {
      this.$bvModal.hide("edit-event-modal");
    },
    edit(event) {
      this.updateMtcEvent(event)
        .then(() => {
          this.makeToast("Update event", "Event has been updated", "success");
        })
        .catch((error) => {
          this.makeToast("Update event", error, "danger");
        });
    },
    passEvent(type, event) {
      if (type == "edit") {
        this.event = event;
        this.showModalEdit();
      }else if(type == "delete"){
        this.$swal
          .fire({
            title: this.$t("modals.askValidDelete.text"),
            text: this.$t("modals.cantRevert.text"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: this.$t("buttons.validDelete.text"),
            cancelButtonText: this.$t("buttons.cancel.text"),
            showLoaderOnConfirm: true,
            preConfirm: () => {
              this.delMtcEvent(event.id)
                .then((response) => {
                  this.$emit("updatePage");
                  return response;
                })
                .catch((error) => {
                  this.$swal.showValidationMessage(`Request failed: ${error}`);
                });
            },
            allowOutsideClick: () => !this.$swal.isLoading(),
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.$swal.fire(
                "Deleted!",
                "The event has been deleted.",
                "success"
              );
            }
          })
          .catch((error) => {
            this.makeToast("Alert Error", error, "danger");
          });
      }
      
    },
    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
  watch: {
    events(newVal) {
      this.eventsList = [];
      newVal.map((obj) => {
        this.eventsList.push({
          ...obj,
          region: obj.region.name,
          province: obj.province.name,
          date: moment(obj.createdAt).format("DD-MM-YYYY HH:mm:ss")
        });
      });
    },
  },
};
</script>

<template>
  <div
    class="table-responsive mb-3 bg-white rounded-lg"
    style="min-height: 430px"
  >
    <b-table
      class="mb-0"
      hover
      show-empty
      centred
      :busy="busy"
      :empty-text="$t('dataTable.noEvents.text')"
      :items="eventsList"
      :fields="fieldsComputed"
      v-bind:style="{ cursor: `pointer` }"
      stacked="sm"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <strong>{{ $t("dataTable.loading.text") }}</strong>
        </div>
      </template>
      <template v-slot:cell(actions)="data">
        <div class="d-inline-flex align-items-center">
          <b-button
            type="button"
            class="mr-2"
            variant="light"
            @click="passEvent('edit', events[data.index])"
            >{{ $t("buttons.edit.text") }}
          </b-button>
          <b-button
            type="button"
            variant="danger"
            @click="passEvent('delete', events[data.index])"
            >{{ $t("buttons.delete.text") }}
          </b-button>
        </div>
      </template>
    </b-table>
    <EditModal
      v-bind:event="event"
      :regions="regions"
      :provinces="provinces"
      @editEvent="edit"
    />
  </div>
</template>
<style>
.tdClass {
  text-align: center;
}
</style>