<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import AddMtcEvent from "./add-mtc-event";

import Stat from "./widgets";
import EventsData from "./events-data";
import Recap from "./recap";
import ProgPrev from "./prog-prev";
import ProgCorr from "./prog-corr";

import {
  layoutMethods,
  layoutComputed,
  mtcEventsMethods,
  regionsMethods,
  provincesMethods,
  mtcEventsComputed,
  regionsComputed,
  provincesComputed,
} from "@/state/helpers";
/**
 * Maintenance component
 */
export default {
  page: {
    title: "Maintenance",
    meta: [{ name: "Maintenance" }],
  },
  components: {
    Layout,
    PageHeader,
    AddMtcEvent,
    Stat,
    EventsData,
    Recap,
    ProgPrev,
    ProgCorr,
  },
  mounted() {},
  created() {
    this.getCountMonthRegion();
  },
  data() {
    return {
      title: "Maintenance",
      eventsData: {},
      regionsData: [],
      provincesData: [],
      countByRegion: [],
      countByMonths: [],
      countByCurrentMonth: [],
      busy: true,
      currentPage: 1,
      perPage: 6,
    };
  },
  computed: {
    ...layoutComputed,
    ...mtcEventsComputed,
    ...regionsComputed,
    ...provincesComputed,
    items() {
      return [
        {
          text: "Nextrack",
        },
        {
          text: "Maintenance",
          active: true,
        },
      ];
    },
  },
  methods: {
    ...layoutMethods,
    ...mtcEventsMethods,
    ...regionsMethods,
    ...provincesMethods,
    retrieveRegions() {
      return this.getRegionsBy({ limit: 100 }).then((regions) => {
        return regions;
      });
    },
    retrieveProvinces() {
      return this.getProvincesBy({ limit: 100 }).then((provinces) => {
        return provinces;
      });
    },
    retrieveMtcEvents({ perPage, page }) {
      let params = {
        page: page ? page : this.currentPage,
        limit: perPage ? perPage : this.perPage,
        sortBy: "createdAt:desc",
        populate: "region,province",
      };
      let paramsFilter = this.cleanObject(params);
      this.busy = true;
      return this.getMtcEventsBy(paramsFilter).then((events) => {
        this.busy = false;
        return events;
      });
    },
    cleanObject(obj) {
      Object.keys(obj).forEach((key) => {
        if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
          delete obj[key];
        }
      });
      return obj;
    },
    openAdd() {
      this.showModalAdd();
    },
    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    showModalAdd() {
      this.$bvModal.show("add-mtcevent-modal");
    },
    hideModalAdd() {
      this.$bvModal.hide("add-mtcevent-modal");
    },
    add(mtcevent) {
      this.addMtcEvent(mtcevent)
        .then(() => {
          this.makeToast(
            "Add mtcevent",
            "Maintenance event has been added",
            "success"
          );
          this.getCountMonthRegion();
        })
        .catch((error) => {
          this.makeToast("Add Event Maintenance", error, "danger");
        });
    },
    getCountMonthRegion() {
      this.getCountByMonthRegionBy({}).then(async () => {
        if (this.getRegions.results) this.regionsData = this.getRegions.results;
        else await this.retrieveRegions();
        if (this.getProvinces.results)
          this.provincesData = this.getProvinces.results;
        else await this.retrieveProvinces();
        this.retrieveMtcEvents({});
        this.getCountByMonthsBy({});
        this.getCountByCurrentMonthBy({});
      });
    },
    handlePageChange(value) {
      this.currentPage = value;
      this.retrieveMtcEvents({});
    },
  },
  watch: {
    getCountByMonthRegion(newVal) {
      this.countByRegion = newVal;
    },
    getCountByMonths(newVal) {
      this.countByMonths = newVal;
    },
    getCountByCurrentMonth(newVal) {
      this.countByCurrentMonth = newVal;
    },
    getRegions(newVal) {
      this.regionsData = newVal.results;
    },
    getProvinces(newVal) {
      this.provincesData = newVal.results;
    },
    getMtcEvents(newVal) {
      this.eventsData = newVal.results;
    },
  },
  beforeDestroy() {
    this.changeLeftSidebarType({ leftSidebarType: "light" });
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12 text-right">
        <b-button type="button" class="mr-2" variant="light" @click="openAdd"
          >{{ $t("buttons.addMtcEvent.text") }}
        </b-button>
        <AddMtcEvent
          @addMtcEvent="add"
          :regions="regionsData"
          :provinces="provincesData"
        />
      </div>
      <div class="col-12 col-md-8">
        <div class="row pb-0">
          <div class="col-12 pb-0">
            <Stat v-bind:data="countByCurrentMonth" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <Recap v-bind:countByRegion="countByRegion" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <EventsData
              v-bind:events="eventsData"
              :regions="regionsData"
              :provinces="provincesData"
              :busy="busy"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div
              class="dataTables_paginate paging_simple_numbers float-right mb-3"
            >
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination
                  v-model="currentPage"
                  :total-rows="getMtcEvents.totalResults"
                  :per-page="perPage"
                  @change="handlePageChange"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-12">
            <ProgPrev v-bind:countByMonths="countByMonths" />
          </div>
        </div> -->
      </div>
      <div class="col-12 col-md-4">
        <ProgPrev v-bind:countByMonths="countByMonths" />
        <ProgCorr v-bind:countByMonths="countByMonths" />
      </div>
    </div>
  </Layout>
</template>