<template>
  <div>
    <b-modal
      id="edit-event-modal"
      ref="edit-event-modal"
      size="md"
      @hidden="cancel"
      :title="$t('modals.editMtcEvent.text')"
    >
      <div class="row">
        <!-- Region -->
        <div class="col-6 col-lg-6">
          <label for="region">{{ $t("modals.region.text") }}</label>
          <b-form-select
            id="region"
            v-model="region"
            :options="regionsData"
            class="mb-1"
            :state="err.region ? false : null"
          ></b-form-select>
          <b-form-invalid-feedback>
            {{ err.region }}
          </b-form-invalid-feedback>
        </div>
        <!-- Province -->
        <div class="col-6 col-lg-6">
          <label for="province">{{ $t("modals.province.text") }}</label>
          <b-form-select
            id="province"
            v-model="mtceventEdit.province"
            :options="provincesData"
            class="mb-1"
            :state="err.province ? false : null"
          ></b-form-select>
          <b-form-invalid-feedback>
            {{ err.province }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <!-- Preventive -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="nbPrev">Préventive</label>
          <b-form-input
            id="nbPrev"
            type="number"
            v-model="mtceventEdit.nbPrev"
            class="mb-1"
            placeholder="Préventive"
            :state="err.nbPrev ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.nbPrev }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <!-- Corrective -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="nbCorr">Corrective</label>
          <b-form-input
            id="nbCorr"
            type="number"
            v-model="mtceventEdit.nbCorr"
            class="mb-1"
            placeholder="Corrective"
            :state="err.nbCorr ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.nbCorr }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <!-- Réglage -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="nbRegl">Réglage</label>
          <b-form-input
            id="nbRegl"
            type="number"
            v-model="mtceventEdit.nbRegl"
            class="mb-1"
            placeholder="Réglage"
            :state="err.nbRegl ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.nbRegl }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="row p-2">
          <div class="col-10 text-right p-1">
            <b-button variant="success" class="text-white" @click="edit(ok)">
              {{ $t("buttons.edit.text") }}
            </b-button>
          </div>
          <div class="col-2 text-center p-1">
            <b-button @click="cancel()">
              {{ $t("buttons.cancel.text") }}
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ["event", "regions", "provinces"],
  created() {},
  data() {
    return {
      regionsData: [
        { value: null, text: this.$t("titles.regions.text"), disabled: true },
      ],
      provincesData: [
        { value: null, text: this.$t("titles.provinces.text"), disabled: true },
      ],
      region: null,
      mtceventEdit: {
        region: null,
        province: null,
        nbPrev: 0,
        nbCorr: 0,
        nbRegl: 0,
      },
      err: {
        region: null,
        province: null,
        nbPrev: null,
        nbCorr: null,
        nbRegl: null,
      },
    };
  },
  watch: {
    event(newVal) {
      this.region = newVal.region.id;
      this.mtceventEdit = {
        mtcEventId: newVal.id,
        region: newVal.region.id,
        province: newVal.province.id,
        nbPrev: newVal.nbPrev,
        nbCorr: newVal.nbCorr,
        nbRegl: newVal.nbRegl,
      };
    },
    region(newVal) {
      Object.assign(this.mtceventEdit, {
        region: newVal,
        province:
          this.event.region.id == newVal ? this.event.province.id : null,
      });
      this.retrieveProvinces(newVal);
    },
    regions(newVal) {
      let regionsList = newVal;
      regionsList.map((obj) => {
        this.regionsData.push({ value: obj.id, text: obj.name });
      });
    },
    provinces(newVal) {
      let provincesList = newVal;
      provincesList.map((obj) => {
        this.provincesData.push({ value: obj.id, text: obj.name });
      });
    },
  },
  methods: {
    retrieveProvinces(region) {
      this.provincesData = [
        { value: null, text: this.$t("titles.provinces.text"), disabled: true },
      ];
      let provinces = this.provinces;
      provinces.map((obj) => {
        if (obj.region == region)
          this.provincesData.push({ value: obj.id, text: obj.name });
      });
    },
    cancel() {
      //this.clear();
    },
    clear() {
      (this.region = null),
        (this.mtceventEdit = {
          region: null,
          province: null,
          nbPrev: 0,
          nbCorr: 0,
          nbRegl: 0,
        });
      this.err = {
        region: null,
        province: null,
        nbPrev: null,
        nbCorr: null,
        nbRegl: null,
      };
    },
    edit(ok) {
      this.mtceventEdit.nbPrev = parseInt(this.mtceventEdit.nbPrev);
      this.mtceventEdit.nbCorr = parseInt(this.mtceventEdit.nbCorr);
      this.mtceventEdit.nbRegl = parseInt(this.mtceventEdit.nbRegl);

      if (!this.mtceventEdit.region)
        this.err.region = this.$t("msgError.obligField.text");
      else this.err.region = "";

      if (!this.mtceventEdit.province)
        this.err.province = this.$t("msgError.obligField.text");
      else this.err.province = "";

      if (!this.mtceventEdit.nbPrev && this.mtceventEdit.nbPrev != 0)
        this.err.nbPrev = this.$t("msgError.obligField.text");
      else this.err.nbPrev = "";

      if (!this.mtceventEdit.nbCorr && this.mtceventEdit.nbCorr != 0)
        this.err.nbCorr = this.$t("msgError.obligField.text");
      else this.err.nbCorr = "";

      if (!this.mtceventEdit.nbRegl && this.mtceventEdit.nbRegl != 0)
        this.err.nbRegl = this.$t("msgError.obligField.text");
      else this.err.nbRegl = "";

      if (
        !this.err.region ||
        !this.err.province ||
        !this.err.nbPrev ||
        !this.err.nbCorr ||
        !this.err.nbRegl ||
        !(
          this.mtceventEdit.region == this.event.region.id &&
          this.mtceventEdit.province == this.event.province.id &&
          this.mtceventEdit.nbPrev == this.event.nbPrev &&
          this.mtceventEdit.nbCorr == this.event.nbCorr &&
          this.mtceventEdit.nbRegl == this.event.nbRegl
        )
      ) {
        ok();
        this.$emit("editEvent", this.mtceventEdit);
      }
    },
  },
};
</script>